import React from "react";
import Container from "../components/Container";

import images from "../assets/imgs";

export default function Projects() {
  const {
    project1,
    project2,
    project3,
    project4,
    project5,
    project6,
    project7,
    project8,
    project9,
  } = images;

  return (
    <section className="pt-[12rem] md:pt-[14rem] relative">
      <Container>
        <div className="py-4 pb-8 md:pb-16">
          <h1 className="text-4xl md:text-6xl font-bold tracking-tight">
            Crystal Clear Windows - <br /> Professional Cleaning Services.
          </h1>
          <p className="max-w-[800px] text-[#ddd] mt-6">
            Here at JOCO, we deliver exceptional window cleaning services,
            ensuring your windows are spotless, enhancing your view and home’s
            appeal.
          </p>
        </div>
        <div className="flex flex-col py-16 gap-4 sm:gap-8 lg:gap-16">
          <div className="flex justify-center items-center flex-wrap gap-2 md:gap-4">
            {project1.map((img, index) => (
              <a
                href={img}
                key={index}
                className="overflow-hidden rounded-sm md:rounded-lg border border-[#ffffff50]"
              >
                <img
                  src={img}
                  alt="project image"
                  className="w-[12vw] 2xl:w-[10vw] h-auto object-contain hover:scale-105 transition-transform duration-300 ease-in-out"
                  loading="lazy"
                />
              </a>
            ))}
          </div>
          <div className="flex justify-center items-center flex-wrap gap-2 md:gap-4">
            {project2.map((img, index) => (
              <a
                href={img}
                key={index}
                className="overflow-hidden rounded-sm md:rounded-lg border border-[#ffffff50]"
              >
                <img
                  src={img}
                  alt="project image"
                  className="w-[12vw] 2xl:w-[10vw] h-auto object-contain hover:scale-105 transition-transform duration-300 ease-in-out"
                  loading="lazy"
                />
              </a>
            ))}
          </div>
          <div className="flex justify-center items-center flex-wrap gap-2 md:gap-4">
            {project3.map((img, index) => (
              <a
                href={img}
                key={index}
                className="overflow-hidden rounded-sm md:rounded-lg border border-[#ffffff50]"
              >
                <img
                  src={img}
                  alt="project image"
                  className="w-[12vw] 2xl:w-[10vw] h-auto object-contain hover:scale-105 transition-transform duration-300 ease-in-out"
                  loading="lazy"
                />
              </a>
            ))}
          </div>
          <div className="flex justify-center items-center flex-wrap gap-2 md:gap-4">
            {project4.map((img, index) => (
              <a
                href={img}
                key={index}
                className="overflow-hidden rounded-sm md:rounded-lg border border-[#ffffff50]"
              >
                <img
                  src={img}
                  alt="project image"
                  className="w-[12vw] 2xl:w-[10vw] h-auto object-contain hover:scale-105 transition-transform duration-300 ease-in-out"
                  loading="lazy"
                />
              </a>
            ))}
          </div>
          <div className="flex justify-center items-center flex-wrap gap-2 md:gap-4">
            {project5.map((img, index) => (
              <a
                href={img}
                key={index}
                className="overflow-hidden rounded-sm md:rounded-lg border border-[#ffffff50]"
              >
                <img
                  src={img}
                  alt="project image"
                  className="w-[12vw] 2xl:w-[10vw] h-auto object-contain hover:scale-105 transition-transform duration-300 ease-in-out"
                  loading="lazy"
                />
              </a>
            ))}
          </div>
          <div className="flex justify-center items-center flex-wrap gap-2 md:gap-4">
            {project6.map((img, index) => (
              <a
                href={img}
                key={index}
                className="overflow-hidden rounded-sm md:rounded-lg border border-[#ffffff50]"
              >
                <img
                  src={img}
                  alt="project image"
                  className="w-[12vw] 2xl:w-[10vw] h-auto object-contain hover:scale-105 transition-transform duration-300 ease-in-out"
                  loading="lazy"
                />
              </a>
            ))}
          </div>
          <div className="flex justify-center items-center flex-wrap gap-2 md:gap-4">
            {project7.map((img, index) => (
              <a
                href={img}
                key={index}
                className="overflow-hidden rounded-sm md:rounded-lg border border-[#ffffff50]"
              >
                <img
                  src={img}
                  alt="project image"
                  className="w-[12vw] 2xl:w-[10vw] h-auto object-contain hover:scale-105 transition-transform duration-300 ease-in-out"
                  loading="lazy"
                />
              </a>
            ))}
          </div>
          <div className="flex justify-center items-center flex-wrap gap-2 md:gap-4">
            {project8.map((img, index) => (
              <a
                href={img}
                key={index}
                className="overflow-hidden rounded-sm md:rounded-lg border border-[#ffffff50]"
              >
                <img
                  src={img}
                  alt="project image"
                  className="w-[12vw] 2xl:w-[10vw] h-auto object-contain hover:scale-105 transition-transform duration-300 ease-in-out"
                  loading="lazy"
                />
              </a>
            ))}
          </div>
          <div className="flex justify-center items-center flex-wrap gap-2 md:gap-4">
            {project9.map((img, index) => (
              <a
                href={img}
                key={index}
                className="overflow-hidden rounded-sm md:rounded-lg border border-[#ffffff50]"
              >
                <img
                  src={img}
                  alt="project image"
                  className="w-[12vw] 2xl:w-[10vw] h-auto object-contain hover:scale-105 transition-transform duration-300 ease-in-out"
                  loading="lazy"
                />
              </a>
            ))}
          </div>
        </div>
      </Container>
      <div className="absolute h-[250px] w-[250px] md:h-[500px] md:w-[500px] rounded-full bg-primary blur-[125px] md:blur-[800px] top-[0] translate-y-[-50%] left-[50%] translate-x-[-50%] -z-50"></div>
    </section>
  );
}
