import React, { useState } from "react";
import Container from "./Container";
import logo from "../assets/JO-CO.png";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Menu, X } from "lucide-react";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="fixed w-full z-50">
      <Container className="mt-2">
        <nav className="bg-[#18181890] px-8 rounded-xl border border-[#ffffff50] backdrop-blur-md">
          <div className="flex justify-between items-center">
            <a href="/" aria-label="Home">
              <motion.img
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.97 }}
                src={logo}
                alt="Joco Logo"
                className="object-cover w-[100px] h-[75px]"
              />
            </a>
            <ul className="hidden md:flex items-center text-[#ddd] text-sm gap-8">
              <li>
                <Link
                  to="/projects"
                  className="hover:text-primary transition-all duration-300"
                  aria-label="Projects"
                >
                  Projects
                </Link>
              </li>
              <motion.a
                href="mailto:detailing.joco@gmail.com"
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.97 }}
                className="text-white text-sm bg-secondary px-6 py-2.5 rounded-md cursor-pointer "
                aria-label="Get a Free Quote"
              >
                Get a Free Quote
              </motion.a>
            </ul>
            <motion.button
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.97 }}
              className="block md:hidden"
              onClick={() => setIsOpen(!isOpen)}
            >
              {!isOpen ? <Menu /> : <X />}
            </motion.button>
          </div>
          {isOpen && (
            <ul className="md:hidden text-[#ddd] text-sm pb-8 flex flex-col gap-6 items-end">
              <li>
                <Link
                  to="/projects"
                  className="hover:text-primary transition-all duration-300"
                  aria-label="Projects"
                >
                  Projects
                </Link>
              </li>
              <motion.div
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.97 }}
              >
                <a
                  href="mailto:detailing.joco@gmail.com"
                  className="text-white text-sm bg-secondary px-6 py-2.5 rounded-md cursor-pointer"
                  aria-label="Get a Free Quote"
                >
                  Get a Free Quote
                </a>
              </motion.div>
            </ul>
          )}
        </nav>
      </Container>
    </header>
  );
}
