import p1img1 from "../assets/project1/IMG_0848.jpeg";
import p1img2 from "../assets/project1/IMG_0849.jpeg";
import p1img3 from "../assets/project1/IMG_0850.jpeg";
import p1img4 from "../assets/project1/IMG_0851.jpeg";
import p1img5 from "../assets/project1/IMG_0852.jpeg";
import p1img6 from "../assets/project1/IMG_0853.jpeg";
import p1img7 from "../assets/project1/IMG_0855.jpeg";
import p1img8 from "../assets/project1/IMG_0856.jpeg";
import p1img9 from "../assets/project1/IMG_0857.jpeg";
import p1img10 from "../assets/project1/IMG_0858.jpeg";

import p2img1 from "../assets/project2/IMG_0871.jpeg";
import p2img2 from "../assets/project2/IMG_0872.jpeg";
import p2img3 from "../assets/project2/IMG_0873.jpeg";
import p2img4 from "../assets/project2/IMG_0874.jpeg";
import p2img5 from "../assets/project2/IMG_0875.jpeg";
import p2img6 from "../assets/project2/IMG_0877.jpeg";

import p3img1 from "../assets/project3/IMG_0646.jpeg";
import p3img2 from "../assets/project3/IMG_0648.jpeg";
import p3img3 from "../assets/project3/IMG_0649.jpeg";
import p3img4 from "../assets/project3/IMG_0650.jpeg";

import p4img1 from "../assets/project4/IMG_0581.jpeg";
import p4img2 from "../assets/project4/IMG_0583.jpeg";
import p4img3 from "../assets/project4/IMG_0585.jpeg";

import p5img1 from "../assets/project5/IMG_0476.jpeg";
import p5img2 from "../assets/project5/IMG_0481.jpeg";
import p5img3 from "../assets/project5/IMG_0482.jpeg";
import p5img4 from "../assets/project5/IMG_0486.jpeg";
import p5img5 from "../assets/project5/IMG_0487.jpeg";
import p5img6 from "../assets/project5/IMG_0488.jpeg";
import p5img7 from "../assets/project5/IMG_0490.jpeg";

import p6img1 from "../assets/project6/IMG_0401.jpeg";
import p6img2 from "../assets/project6/IMG_0402.jpeg";
import p6img3 from "../assets/project6/IMG_0406.jpeg";

import p7img1 from "../assets/project7/IMG_0313.jpeg";
import p7img2 from "../assets/project7/IMG_0316.jpeg";
import p7img3 from "../assets/project7/IMG_0320.jpeg";

import p8img1 from "../assets/project8/IMG_0293.jpeg";
import p8img2 from "../assets/project8/IMG_0294.jpeg";
import p8img3 from "../assets/project8/IMG_0297.jpeg";

import p9img1 from "../assets/project9/IMG_0220.jpeg";
import p9img2 from "../assets/project9/IMG_0223.jpeg";
import p9img3 from "../assets/project9/IMG_0224.jpeg";
import p9img4 from "../assets/project9/IMG_0228.jpeg";

const project1 = [
  p1img1,
  p1img2,
  p1img3,
  p1img4,
  p1img5,
  p1img6,
  p1img7,
  p1img8,
  p1img9,
  p1img10,
];
const project2 = [p2img1, p2img2, p2img3, p2img4, p2img5, p2img6];
const project3 = [p3img1, p3img2, p3img3, p3img4];
const project4 = [p4img1, p4img2, p4img3];
const project5 = [p5img1, p5img2, p5img3, p5img4, p5img5, p5img6, p5img7];
const project6 = [p6img1, p6img2, p6img3];
const project7 = [p7img1, p7img2, p7img3];
const project8 = [p8img1, p8img2, p8img3];
const project9 = [p9img1, p9img2, p9img3, p9img4];

const images = {
  project1,
  project2,
  project3,
  project4,
  project5,
  project6,
  project7,
  project8,
  project9,
};

export default images;
