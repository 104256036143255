import React from "react";
import Container from "./Container";
import { motion } from "framer-motion";

export default function Hero() {
  return (
    <section className="pt-[12rem] md:pt-[14rem] pb-16 relative">
      <Container className="flex flex-col items-center justify-center">
        <h1 className="text-4xl md:text-6xl font-bold text-center tracking-tight">
          We’re your clear <br />
          choice for a brighter home
        </h1>
        <p className="text-center max-w-[800px] text-[#ddd] mt-6">
          Experience the JOCO difference with our top-notch window cleaning
          services. Enjoy streak-free, sparkling windows that brighten your home
          and elevate its beauty. Satisfaction guaranteed.
        </p>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-6 mt-10">
          <motion.a
            href="#calculator"
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.97 }}
            className="text-white text-sm bg-primary px-8 py-3 rounded-md cursor-pointer "
          >
            Calculate Your Price
          </motion.a>
          <motion.a
            href="mailto:detailing.joco@gmail.com"
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.97 }}
            className="text-white text-sm bg-secondary px-8 py-3 rounded-md cursor-pointer"
          >
            Get a Free Quote
          </motion.a>
        </div>
      </Container>
      <div className="absolute h-[250px] w-[250px] md:h-[500px] md:w-[500px] rounded-full bg-primary blur-[125px] md:blur-[800px] top-[0] translate-y-[-50%] left-[50%] translate-x-[-50%] -z-50"></div>
    </section>
  );
}
