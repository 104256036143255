import React from "react";
import Container from "./Container";
import logo from "../assets/JO-CO.png";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FaInstagram, FaTiktok } from "react-icons/fa6";

export default function Footer() {
  return (
    <footer className="py-8 bg-[#181818] border-t border-[#ffffff50] relative">
      <Container className="flex flex-col gap-12">
        <div className="flex flex-col sm:flex-row gap-4 justify-between px-8">
          <a href="/" aria-label="home" className="mx-auto sm:mx-0">
            <motion.img
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.97 }}
              src={logo}
              alt="Joco Logo"
              className="w-[200px] h-[75px] object-cover"
            />
          </a>
          <div className="flex flex-col sm:flex-row gap-4 sm:gap-16">
            <div className="flex flex-col items-center sm:items-start gap-2">
              <h3 className="font-bold">Company</h3>
              <ul className="text-sm">
                <li>
                  <Link
                    to="/projects"
                    className="hover:text-primary transition-all duration-300 text-[#ddd]"
                  >
                    Projects
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex flex-col items-center sm:items-start gap-2">
              <h3 className="font-bold">Email Us</h3>
              <a
                href="mailto:detailing.joco@gmail.com"
                className="text-sm hover:text-primary transition-all duration-300 text-[#ddd]"
              >
                detailing.joco@gmail.com
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row items-center gap-2 justify-between px-8">
          <p className="text-[#ddd] text-sm">
            Copyright ©2024 JOCO | All rights reserved.
          </p>
          <div className="flex gap-4 items-center text-lg text-[#ddd]">
            <a
              href="https://www.instagram.com/joco.detailing/"
              className="hover:text-primary transition-all duration-300"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.tiktok.com/@joco.detailing"
              className="hover:text-primary transition-all duration-300"
            >
              <FaTiktok />
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
}
