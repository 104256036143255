import React from "react";
import Container from "./Container";
import { Sparkle } from "lucide-react";

const services = [
  {
    title: "Window Cleaning",
    properties: [
      "Full exterior spray using chemicals sills",
      "Squeegee off any residue",
      "Glass-friendly chemicals",
    ],
  },
  {
    title: "Interior Window Cleaning",
    properties: [
      "Full exterior spray using chemicals sills",
      "Squeegee off any residue",
      "Non-Toxic chemicals",
    ],
  },
];

export default function Services() {
  return (
    <section className="py-16" id="services">
      <Container>
        <h2 className="text-2xl md:text-4xl font-bold text-center tracking-tight mb-4">
          Our Services
        </h2>
        <p className="mb-10 text-[#ddd] text-center max-w-lg mx-auto">
          We offer a wide range of window cleaning services to keep your home or
          business looking its best.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
          {services.map((service, i) => (
            <div
              className="bg-[#181818] p-4 rounded-xl border border-[#ffffff50]"
              key={i}
            >
              <h3 className="md:text-xl font-bold mb-4">{service.title}</h3>
              <ul className="pl-4 flex flex-col gap-4">
                {service.properties.map((property, j) => (
                  <li className="flex items-center gap-2 text-sm" key={j}>
                    <Sparkle size={16} className="text-primary" /> {property}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="bg-[#181818] p-4 rounded-xl border border-[#ffffff50]">
          <h3 className="md:text-xl font-bold mb-4">Additional Context</h3>
          <div className="flex flex-col gap-2">
            <p>
              Any paint, varnish, stain, stubborn residue, or tape, may result
              in an additional charge. In a normal window cleaning job, we cover
              any natural build-up on your windows!
            </p>
            <p>
              <b>Servicing:</b> Chaska, MN | Chanhassen, MN | Eden Prairie, MN |
              Excelsior, MN | Plymouth, MN | Victoria, MN | Bloomington, MN |
              Tonka Bay, MN | Minnetonka, MN | Greenwood, MN | Mound, MN |
              Hopkins, MN | Deephaven, MN | Orono, MN | Wayzata, MN &
              surrounding areas
            </p>
            <p className="text-sm text-[#ddd]">
              *Certain exceptions can be made. Please reach out via{" "}
              <a
                href="mailto:detailing.joco@gmail.com"
                aria-label="email"
                className="underline"
              >
                Email
              </a>
              *
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
}
