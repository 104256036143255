import { Home } from "lucide-react";
import React from "react";
import Container from "./Container";
import jacob from "../assets/jacob.png";
import owen from "../assets/owen.png";

export default function About() {
  return (
    <section className="py-16" id="about">
      <Container>
        <div className="flex">
          <div className="p-3 bg-[#181818] rounded-full border border-primary mb-6 hover:shadow-md hover:shadow-[#32c1fd90] transition-all duration-300">
            <Home size={16} strokeWidth={1} className="text-primary" />
          </div>
        </div>
        <h3 className="text-primary mb-2 ml-1">Our Story</h3>
        <h2 className="text-2xl md:text-4xl font-bold tracking-tight mb-6">
          About JOCO
        </h2>
        <div className="mb-10">
          <div className=" flex flex-col gap-2 max-w-2xl text-[#ddd]">
            <p>
              We are two seniors at Southwest Christian High School attending
              Grand Canyon University in the upcoming fall. We both wanted to
              find a way to work for ourselves doing something we are both
              experienced in.
            </p>
            <p>
              Both of us hold ourselves to a high standard and guarantee
              customer satisfaction.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-[#181818] p-4 rounded-xl border border-[#ffffff50] flex flex-col sm:flex-row items-center gap-4">
            <img
              src={jacob}
              alt="Jacob"
              className="w-[150px] rounded-xl border border-primary"
            />
            <p>
              Hi, my name is Jake. I am a senior at Southwest Christian High
              School. I play soccer and snowboard in my free time. I am majoring
              in entrepreneurship at Grand Canyon University this fall.
            </p>
          </div>
          <div className="bg-[#181818] p-4 rounded-xl border border-[#ffffff50] flex flex-col sm:flex-row items-center gap-4">
            <img
              src={owen}
              alt="Owen"
              className="w-[150px] rounded-xl border border-primary"
            />
            <p>
              Hey, my name is Owen. I am a student at Southwest Christian High
              School. I enjoy sports, being outdoors, hanging out with friends
              and family, and working. I plan to major in Business this fall at
              Grand Canyon University.
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
}
