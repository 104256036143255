import React, { useState } from "react";
import { motion } from "framer-motion";
import Container from "./Container";

export default function Calculator() {
  const [panes, setPanes] = useState(0);
  const [price, setPrice] = useState(0);

  const handleChange = (event) => {
    setPanes(event.target.value);
  };

  const handleSubmit = (event) => {
    if (panes <= 0) {
      alert("Please enter a valid number of panes");
      return;
    }

    event.preventDefault();
    setPrice(panes * 5);
  };

  return (
    <Container className="py-16">
      <form
        className="bg-[#181818] border border-[#ffffff50] p-8 rounded-xl max-w-[850px] mx-auto"
        id="calculator"
        onSubmit={handleSubmit}
      >
        <h2 className="text-2xl md:text-4xl">Calculate your price now!</h2>
        <p className="text-sm text-[#ddd] mb-6 ">
          <b>Disclaimer:</b> Larger windows are charged at a higher rate.
        </p>
        <div className="flex flex-col gap-1">
          <label htmlFor="panes" className="text-sm">
            Number of Window Panes:
          </label>
          <input
            type="number"
            id="panes"
            name="panes"
            className="text-black outline-none p-2 rounded-md"
            onChange={handleChange}
          />
        </div>
        <motion.button
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.97 }}
          className="bg-primary text-white px-8 py-3 rounded-md cursor-pointer mt-4 text-sm"
        >
          Calculate
        </motion.button>

        <div className="flex gap-2 mt-6 items-center">
          <h3 className="md:text-xl">Your estimated price:</h3>
          <p className="text-xl md:text-2xl font-semibold">${price}</p>
        </div>
      </form>
    </Container>
  );
}
