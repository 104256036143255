import React from "react";
import About from "../components/About";
import Calculator from "../components/Calculator";
import Hero from "../components/Hero";
import Services from "../components/Services";

export default function Home() {
  return (
    <>
      <Hero />
      <Calculator />
      <Services />
      <About />
    </>
  );
}
